





























import { Vue, Component } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveAdquirenteUseCase } from '@/usecases'
import { Adquirente } from '@/models'

@Component

export default class DialogoDeEdicaoDeAdquirente extends Vue {
	adquirente: Adquirente | null = null
	mostra = false
	salvando = false
	saveAdquirenteUseCase = new SaveAdquirenteUseCase()

	mostrar(adquirente) {
		this.mostra = true
		this.adquirente = adquirente
	}

	esconder() {
		this.mostra = false
	}

	async salvar() {
		if (!this.adquirente) return
		try {
			this.salvando = true
			const adquirente = {
				id: this.adquirente.id,
				nome: this.adquirente.nome.toUpperCase(),
			}
			const adquirenteSalvo = this.adquirente.id
				? await this.saveAdquirenteUseCase.update(adquirente)
				: await this.saveAdquirenteUseCase.create(adquirente)

			this.adquirente.id
				? this.$emit('update', adquirenteSalvo)
				: this.$emit('create', adquirenteSalvo)
			this.mostra = false
			AlertModule.setSuccess('Adquirente salvo com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}
}
