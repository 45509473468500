



















































import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue';
import DialogoDeEdicaoDeAdquirente from '@/components/venda/DialogoDeEdicaoDeAdquirente.vue';
import { Adquirente, Page, Pageable } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import { FindAdquirenteUseCase } from '@/usecases';
import { CancelToken } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
	components: {
		DataTableDeCrudPaginado,
		DialogoDeEdicaoDeAdquirente,
	},
})
export default class TelaDeCadastroDeAdquirente extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicaoDeAdquirente!: DialogoDeEdicaoDeAdquirente

	findAdquirenteUseCase = new FindAdquirenteUseCase()
	busca = ''
	adquirente: Adquirente[] = []
	carregando = false
	editando = false

	headers = [
		{ text: 'Adquirente', value: 'nome' },
	]



	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<Adquirente>> {
		const pagina = await this.findAdquirenteUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.adquirente = pagina.content
		return pagina
	}

	
	abrirDialogoDeEditarAdquirente(indice: number) {
		try {
			if(!this.temRecurso('alterar', "adquirente")) {
				throw new Error('Necessário permissão para editar')
			}
			this.editando = true
			const adquirente = this.adquirente[indice]
			this.dialogoDeEdicaoDeAdquirente.mostrar(adquirente)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	abrirDialogoDeCriarAdquirente() {
		try {
			if(!this.temRecurso('criar', 'adquirente')) 
				throw new Error('Necessário permissão para criar')
			this.editando = true
			const adquirente = {id:"",nome:""}
			this.dialogoDeEdicaoDeAdquirente.mostrar(adquirente)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	criarRegistro(adquirente: Adquirente) {
		this.adquirente.push(adquirente)
	}

	atualizarRegistro(adquirente: Adquirente) {
		const indice = this.adquirente.findIndex( adquirenteExistente => adquirenteExistente.id === adquirente.id)
		this.adquirente.splice(indice, 1, adquirente)
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}

	@Watch('busca')
	novaBusca() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}

	@Watch('carregando')
	onChangeMostrar() {
		this.dataTablePaginado.reiniciar({
			busca: this.busca,
		})
	}
}
